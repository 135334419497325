:root {
  --pj-pink: #fff5eb;
  --pj-black: #1d1817;
  --pj-disabled: #828282;
  --pj-white: #fbfafa;
  --pj-orange: #ee7b30;
  --hotel-grey: #e5e5e5;
  --pj-success: #1bb250;
  --pj-failed: #dc4639;
  --pj-link: #304d6d;
  --pj-grey: #cecece;
  --heyjack-font: 'Hey Jack', sans-serif;
}

@font-face {
  font-family: 'Hey Jack';
  src: url('./fonts/HeyJack.woff2') format('woff2'), url('./fonts/HeyJack.woff') format('woff'),
    url('./fonts/HeyJack.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  src: url("./fonts/Montserrat-Regular.ttf");
}
body {
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  min-width: 300px;
  background-color: var(--hotel-grey);
  background-image: url('./assets/Leaf.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
